.sidebar {
  width: 200px; /* Reduced width */
  background-color: #ffffff; /* Fully opaque and white */
  padding: 20px;
  position: fixed;
  top: 80px;
  bottom: 80px;
  left: 0;
  overflow-y: auto;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.2); /* Stronger shadow */
  overflow-x: hidden;
}

.sidebar h2 {
  margin-top: 0;
  font-size: 18px; /* Smaller font size for title */
}

.document-list {
  list-style: none;
  padding: 0;
  margin: 20px 0 30px; /* More space between the list and the button */
}

.document-item {
  position: relative; /* Required for positioning the tooltip */
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.document-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.document-item .tooltip-text {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  bottom: 120%; /* Position above the document item */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 1000;
}

.document-item:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.document-item span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Ellipses for text overflow */
  color: #150C2A; /* New text color for sources */
}

.add-document-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #D6E1F6; /* New background color */
  color: #3067D4; /* New text color */
  border: none;
  border-radius: 5px;
  font-size: 14px; /* Smaller text */
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
}

.add-document-button:hover {
  background-color: #B8CDF3; /* Slightly darker hover color to maintain contrast */
}

.add-document-button:before {
  content: "+ ";
  margin-right: 5px; /* Space between + and text */
}
