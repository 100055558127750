body {
  margin: 0;
  font-family: 'Lato', Arial, sans-serif; 
  background-color: #ffffff;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
