.process-upload-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.process-upload-modal-content {
    background: white;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    text-align: center;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.process-upload-modal-header {
    background-color: #3B71CA;
    padding: 10px 0; /* Reduced the height */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px; /* Reduced the height */
}

.process-upload-modal-header h2 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.process-upload-modal-body {
    padding: 20px;
    color: #4F4F4F;
}

.process-upload-modal-body p {
    margin: 15px 0 0;
    font-weight: bold;
    font-size: 16px; /* Increased by 2px */
}

.process-upload-radial-progress-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
}

.process-upload-radial-progress {
    width: 40px;  /* Reduced size by ~33% */
    height: 40px; /* Reduced size by ~33% */
    border-radius: 50%;
    border: 7px solid #C2D2F2; /* Adjusted the border size */
    border-top-color: #3067D4;
    animation: process-upload-spin 1.5s linear infinite;
}

@keyframes process-upload-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
