.footer {
    height: 80px; /* Increased height to match nav bar */
    background-color: #FFFFFF; /* Changed background color to white */
    padding: 10px 20px;
    text-align: center;
    border-top: 1px solid #E5E5E5;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box; /* Ensure padding and border are included in height */
  }
  