.expectedInformation {
  width: 100%;
  max-width: 300px;
  padding: 20px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  background-color: #F1F5FC;
  margin: 20px 0;
  font-size: 14px;
  color: #555555;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.header h3 {
  font-size: 24px;
  color: #1B2f4a;
  font-weight: bold;
  margin: 0;
}

.infoIcon {
  width: 16px;
  height: 16px;
  margin-left: 0px; /* Closer to the text */
}

.infoSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.infoCategory {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.categoryIcon {
  margin-bottom: 5px;
}

.personalInformationIcon {
  width: 17.5px;
  height: 20px;
}

.terminationIcon {
  width: 25px;
  height: 20px;
}

.payrollIcon {
  width: 25px;
  height: 17.5px;
}

.otherPayrollIcon {
  width: 19.15px;
  height: 20px;
}

.categoryContent {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.categoryContent strong {
  width: 120px; /* Adjust the width for proper wrapping */
  font-size: 16px; /* Title size */
  font-weight: bold; /* Bold title */
}

.infoItems {
  display: flex;
  flex-direction: column;
  margin-top: 2px; /* Closer gap between title and items */
}

.infoItems div {
  margin-bottom: 5px;
  white-space: nowrap; /* Prevent wrapping */
}
