html, body {
  background-color: #FFFFFF;
}


.App {
  background-color: #FFFFFF;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100%;
  overflow-x: hidden;
  position: relative;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  max-width: 100vw;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3067D4;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}