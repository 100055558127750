.processing-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.processing-modal {
    background: white;
    width: 570px;
    padding: 0; /* Removed padding to handle separately */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    text-align: center;
}

.processing-modal-header {
    padding: 70px 62px 20px 62px; /* Added top padding and reduced bottom padding */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.processing-modal-header h2 {
    margin-top: 0;
    margin-bottom: 10px; /* Reduced bottom margin between the header and the first paragraph */
    color: #1B2F4A;
    white-space: nowrap;
}

.processing-modal-header p {
    margin-bottom: 10px; /* Reduced bottom margin between the paragraphs */
    color: #626262;
    white-space: nowrap;
    font-weight:normal;
}

.processing-modal-header p,
.checklist-item p {
    font-weight: normal; /* Ensure the same font weight */
    color: #626262; /* Ensure the same text color */
    font-size: 16px; /* Ensure the same font size */
}


.processing-modal-header p:last-of-type {
    margin-bottom: 0; /* Ensures the last paragraph doesn't have extra space below */
}

.processing-modal-body {
    padding: 0 72px 50px 72px; /* Separate padding for the body */
    box-sizing: border-box;
}

.radial-progress-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
}

.radial-progress {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 12px solid #C2D2F2;
    border-top-color: #3067D4;
    animation: spin 1.5s linear infinite;
}

.left-stars {
    position: absolute;
    bottom: 0px;
    left: 125px;
    width: 30px;
    height: auto;
}

.right-stars {
    position: absolute;
    top: 0px;
    right: 120px;
    width: 30px;
    height: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.stars {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.star {
    color: #1e90ff;
    font-size: 24px;
    margin: 0 5px;
}

.checklist {
    text-align: left;
    margin: 20px 0;
}

.checklist-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.info-icon {
    color: #3067D4;
    font-size: 20px;
    margin-right: 20px;
}

.processing-close-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #3067D4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.processing-close-button:hover {
    background-color: #2854a3;
}
