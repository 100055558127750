.content-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
}

.upload-section {
  display: flex;
  gap: 30px;
  align-items: flex-start;
}

.upload-section > div {
  flex: 1;
}

.upload-section .upload-content {
  flex: 2;
  max-width: 650px;
  padding-top: 20px;
}

.documents-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.documents-title {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 500;
  color: #4F4F4F;
  align-self: flex-start;
}

.continue-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #3067D4;
  color: #FFFFFF;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.continue-button:hover {
  background-color: #2451A6;
}

.button-icon {
  margin-right: 8px;
}

.return-to-table {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #3067D4;
  font-size: 14px;
  font-weight: bold;
  margin: 20px 0;
}

.return-to-table .return-icon {
  margin-right: 8px;
}

/* First instance of "Return to table" - always visible */
.return-to-table-first {
  padding-top: 20px;
}

.actions-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.upload-empty-state {
  width: 100%;
  padding: 30px;
  border: 1px solid #D3D3D3;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  box-sizing: border-box;
}

.upload-empty-state-icon {
  width: 38.5px;
  height: 26.84px;
  margin-bottom: 10px;
}

.upload-empty-state-text {
  font-size: 20px;
  color: #757575;
  font-weight: normal;
  text-align: center;
}

/* New CSS for top action buttons (Return to Table + Submit & Generate) */
.top-actions-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}
