.main-content-header {
  margin-bottom: 20px;
}

.plan-year {
  font-size: 16px;
  color: #757575;
  font-weight: normal;
  margin: 0;
  padding-left: 0; /* Ensure no extra padding or margin */
}

.main-header {
  font-size: 28px;
  margin: 15px 0 0 0; /* Set margin to zero on all sides except for the top */
  padding-left: 0; /* Ensure no extra padding or margin */
  color: #1B2F4A;
}

.description {
  font-size: 16px;
  line-height: 22.4px;
  color: #666666;
}
