.results-content-container {
  display: flex;
  position: relative;
}

.sidebar {
  width: 250px;
  background-color: #f8f9fa;
  padding: 20px;
  position: fixed;
  top: 80px;
  bottom: 80px;
  left: 0;
  overflow-y: auto;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  margin-top: 20px;
  z-index: 1000;
}

.results-content-wrapper {
  flex: 1;
  /* padding-left: 20px; */
  margin-bottom: 80px;
  overflow-y: auto;
  max-width: 100%;
}

.toggle-button-wrapper {
  position: fixed;
  top: 50%;
  left: 240px;
  transform: translate(-50%, -50%);
  z-index: 1000;
  transition: left 0.3s ease;
}

.toggle-sidebar-button {
  cursor: pointer;
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease;
}

.toggle-sidebar-button.rotated {
  transform: rotate(180deg);
}

.no-sidebar .toggle-button-wrapper {
  left: 20px;
}

.content-wrapper {
  flex: 1;
  padding-left: 20px;
  margin-top: 80px;
  margin-bottom: 80px;
  overflow-y: auto;
  max-width: 1200px;
}

.loading-overlay {
  position: fixed; /* Change to fixed to cover the entire screen */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%; /* Ensure full width */
  height: 100%; /* Ensure full height */
  background-color: rgba(128, 128, 128, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #007BFF;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.save-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.save-button-container {
  display: flex;
  justify-content: space-between; /* Update this line */
  margin-bottom: 20px;
}

.delete-button {
  background-color: #DC4C64; /* Red color */
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  padding: 10px 20px; /* Adjust padding to make the button shorter in width */
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #A12626; /* Darker red on hover */
}

.delete-button img {
  width: 16px; /* Adjust the width to the desired size */
  height: 16px; /* Adjust the height to the desired size */
  margin-right: 5px; /* Adjust or remove this to reduce space between icon and text */
  margin-left: 0; /* Ensure there is no left margin */
}


.save-button {
  background-color: #3067D4;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.save-button:hover {
  background-color: #2553a1;
}

.save-button img {
  margin-right: 5px;
}

.dropdown-section {
  margin-bottom: 20px;
  margin-top: 20px;
}

.dropdown-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  margin: 20px 0;
}

.dropdown-icon {
  transition: transform 0.3s ease;
}

.dropdown-icon.collapsed {
  transform: rotate(-90deg);
}

.dropdown-title {
  margin-left: 10px;
  font-weight: 700;
  font-size: 20px;
}

.current-year-title {
  color: #3067D4;
  font-size: 18px;
}

.previous-year-title {
  color: #D43030;
  font-size: 18px;
}

.divider {
  height: 2px;
  background-color: #ccc;
  margin: 20px 0;
}

.processing-warning {
  background-color: #FDECEC;
  color: #7A0202;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 5px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.processing-warning:hover {
  background-color: #FCC;
  color: #500000;
}

.processing-warning-icon {
  margin-right: 5px;
  color: #7A0202;
  font-size: 16px; 
}

.processing-warning:hover .processing-warning-icon {
  color: #500000;
}

.past-census-info-icon-wrapper {
  position: relative;
  display: inline-block;
}

.past-census-info-icon-wrapper {
  position: relative;
  display: inline-block;
}

.past-census-info-icon-wrapper img {
  width: 16px;
  height: 16px;
  margin-left: 8px; /* Keeps spacing between title and icon */
  vertical-align: middle; /* Ensures baseline alignment */
  position: relative;
  top: -2px; /* Adjusts the icon up by 2 pixels */
}


.past-census-info-tooltip {
  position: absolute;
  bottom: 120%; /* Keeps the tooltip above the icon */
  left: 0; /* Aligns the tooltip's top-left corner with the icon */
  background-color: #666666; /* Grey background */
  color: white;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  width: 260px; /* Wider tooltip to limit it to two lines */
  text-align: left; /* Align text to the left */
  z-index: 1000; /* Ensure it appears on top */
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}

.past-census-info-tooltip::after {
  content: '';
  position: absolute;
  top: 100%; /* Position the arrow below the tooltip */
  left: 10px; /* Adjusts arrow to start at the left */
  border-width: 5px;
  border-style: solid;
  border-color: #666666 transparent transparent transparent;
}

.add-employee-button {
  background: none;
  border: none;
  color: #3067D4;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
}

.add-employee-button img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}



/* .onboarding-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent; 
  z-index: 1000;
}

.spotlight-hole {
  position: absolute;
  background: rgba(0, 0, 0, 0.7); 
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.7); 
  z-index: 1001;
}


.results-table {
  position: relative;
  z-index: 1002;
} */