/* src/components/MainLoading.css */
.main-loading-overlay {
    position: fixed;
    top: 0px; /* Adjust based on the height of your TopNavBar */
    bottom: 0px; /* Adjust based on the height of your Footer */
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .main-loading-spinner {
    border: 12px solid #f3f3f3;
    border-top: 12px solid #3067D4;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  