.toolbar {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-right: 50px;
}

.search-bar {
    margin-left: auto; /* This will push it to the right */
    position: relative;
    max-width: 300px;
}

.tabs {
    display: flex;
    border: 1px solid #D5D9E1;
    border-radius: 6px;
    overflow: hidden;
    background-color: #FFFFFF;
}

.tab {
    padding: 10px 16px; /* Ensure equal padding */
    background-color: #FFFFFF;
    color: #344054;
    cursor: pointer;
    border: none;
    text-align: center;
    border-right: 1px solid #D5D9E1; /* Border between tabs */
}

.tab:last-child {
    border-right: none; /* Remove right border on the last tab */
}

.tab.active {
    background-color: #F9FAFB; /* Updated active background color */
    color: #1D2939;
    font-weight: 500;
}

.tab:hover {
    background-color: #F9FAFB; /* Same hover effect for active background */
}

.tab.active:hover {
    background-color: #F9FAFB; /* Keep the hover effect consistent */
}


.search-bar--no-margin {
    margin: 0; /* Removes any margin when isOldData is true */
}

.search-bar input {
    width: 100%;
    padding: 10px 15px 10px 35px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.search-bar .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
}

.buttons {
    display: flex;
    gap: 15px;
}

.button-add-manually, .button-upload-file {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    min-width: 150px;
    transition: background-color 0.3s;
    padding: 12px 15px 10px 15px; /* Adjust bottom padding to move text down */
    font-size: 12px;
    height: 40px;
    line-height: 16px; /* Adjust line-height to align text better */
}

.button-add-manually {
    padding: 12px 15px;
}

.button-upload-file {
    padding: 12px 25px 12px 15px;
}

.button-add-manually {
    border: 1px solid #3067D4;
    color: #007bff;
    background-color: #fff;
    font-size: 12px;
    border-radius: 8px;
    font-weight: 700;
}

.button-add-manually:hover {
    background-color: #e6e6e6;
}

.button-upload-file {
    border: none;
    color: #fff;
    background-color: #3067D4;
    font-size: 12px;
    border-radius: 8px;
    font-weight: 700;
}

.button-upload-file:hover {
    background-color: #2553a1;
}

.button-add-manually img, .button-upload-file img {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    vertical-align: middle;
}

/* iPad Pro portrait mode adjustments (1024 x 1366) */
@media screen and (max-width: 1024px) and (orientation: portrait) {
    .search-bar {
        max-width: 150px; /* Further reduced from 200px to 150px */
    }
  
    .tab {
      font-size: 12px; /* Smaller text in tabs */
      padding: 8px 12px; /* Slightly reduced padding */
    }
  
    .button-add-manually, 
    .button-upload-file {
      font-size: 11px; /* Smaller text in buttons */
      min-width: 130px; /* Slightly reduced minimum width */
    }
  }
