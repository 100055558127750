.document-card {
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #027A48;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
  min-width: 0;
}

.document-card.failed-keyword-check {
  border: 1px solid #FF0000;
}

.document-card-status.error-status {
  color: #FF0000;
  font-weight: normal;
}

.document-card-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border: 1px solid #A9ACB0;
  border-radius: 5px;
  margin-right: 10px;
  flex-shrink: 0;
}

.document-card-img {
  height: 30px;
  width: auto;
}

.document-card-details {
  flex: 1;
  min-width: 0; /* Allows truncation to work */
}

.document-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 0; /* Allows truncation to work */
}

.document-card-name {
  font-size: 20px;
  color: #363939;
  font-weight: normal;
  margin-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 80px);
}

.document-card-buttons {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 10px;
}

.document-card-button {
  position: relative; /* Make the button the positioning context */
  cursor: pointer;
  background: transparent;
  border: none;
  margin-left: 10px;
}

.document-card-button:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  top: 50%; /* Position relative to the button's center */
  left: 50%; /* Position relative to the button's center */
  transform: translate(0%, 50%); /* Adjust position to align tooltip's top left corner */
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 100;
}

.document-card-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.document-card-size {
  font-size: 14px;
  color: #757575;
}

.document-card-status {
  font-size: 14px;
  color: #027A48;
}

.document-card-progress-container {
  width: 100%;
  height: 8px;
  background-color: #E5E5E5;
  border-radius: 4px;
  margin-top: 10px;
}

.document-card-progress {
  height: 100%;
  background-color: #007BFF;
  border-radius: 4px;
}
