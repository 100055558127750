.top-nav-bar {
  height: 80px;
  background-color: #FFFFFF;
  border-bottom: 3px solid;
  border-image: linear-gradient(to right, #3067D4 75%, #F2F5FC 75%) 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.logo {
  font-weight: bold;
  font-size: 24px;
}

.logo img {
  height: 25px;
  width: auto;
  margin-left:15px;
}

.nav-links span,
.nav-links a {
  font-size: 16px;
  color: #3067D4;
  margin-left: 20px;
  font-weight: 700;
}

.nav-links a {
  text-decoration: none;
  cursor: pointer;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative; /* Add this */
}

.dropdown-menu {
  position: absolute;
  top: 85px;
  right: 10px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0; /* Ensure button fills the dropdown */
  width: 150px;
  z-index: 1000;
}

.signout-button {
  display: flex;
  align-items: center;
  padding: 16px 20px;  /* Adjusted padding for a more spacious button */
  cursor: pointer;
  width: 100%;  /* Full width to cover entire dropdown */
  box-sizing: border-box; /* Ensure padding is included in width */
}

.signout-button:hover {
  background-color: #f0f0f0; /* Full hover effect */
}

.signout-icon {
  margin-right: 0px; /* Restored nice spacing between icon and text */
  width: 16px;
  height: 14px;
}

.signout-button span {
  color: rgba(51, 51, 51, 1); /* Text color */
  font-weight: 400;
  font-size: 14px;
}

.help-container {
  position: relative;
  display: flex;
  align-items: center;
}

.help-icon {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.help-icon img {
  width: 20px;
  height: 20px;
}