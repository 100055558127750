/* Overlay */
.delete-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Content */
.delete-modal-content {
    background: white;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    text-align: center;
    position: relative;
}

/* Top Blue Banner */
.delete-modal-header {
    background-color: #3B71CA;
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delete-modal-header h2 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.delete-modal-header .close-icon {
    font-size: 24px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    display: flex;           /* Add this */
    align-items: center;     /* Add this */
    height: 100%;           /* Add this */
    /* or alternatively you could use: */
    /* line-height: 18px; */  /* To match the h2 font-size */
}

/* Modal Body */
.delete-modal-body {
    padding: 20px;
}

/* Bell Icon */
.delete-modal-icon img {
    width: 80px;
    height: 70px;
    margin: 15px 0;
}

/* Message */
.delete-modal-message {
    color: #4F4F4F;
    margin: 0;
    padding: 10px 0 0 0;
}

.delete-modal-message .action-message {
    color: #4F4F4F;
    margin: 0;
}

.delete-modal-message .delete-message {
    color: #4F4F4F;
    font-weight: bold;
    margin-top: 20px; /* Increased the space between the "This action cannot be undone" and bold message */
    padding: 0 20px; /* Added padding to create space on the sides of the text */
    text-align: center; /* Keeps the text centered even with padding */
}

/* Divider (Horizontal line) */
.delete-modal-divider {
    height: 2px;
    background-color: #F5F5F5;
    margin: 0px 0; /* Further reduced space between divider and bold message */
}

/* Actions (Buttons) */
.delete-modal-actions {
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 12px 0; /* Balanced the padding between buttons and modal */
}

/* Centered Buttons Styling */
.centered-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
}

/* Buttons */
.delete-modal-delete-button,
.delete-modal-close-button {
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}

.delete-modal-delete-button {
    background-color: #3B71CA;
    color: white;
    border: none;
}

.delete-modal-delete-button:hover {
    background-color: #2D5BA7;
}

.delete-modal-close-button {
    background-color: white;
    color: #3B71CA;
    border: 2px solid #3B71CA;
}

.delete-modal-close-button:hover {
    background-color: #f0f0f0;
}
