.table-container {
    overflow-x: scroll;
    overflow-y: visible;
    position: relative;
    min-height: 300px;
    -webkit-overflow-scrolling: touch;  /* Smooth scrolling for iOS */
}

/* Firefox-only styles */
@-moz-document url-prefix() {
    .table-container {
        scrollbar-width: auto;
        scrollbar-color: #575757 #D9D9D9;
    }
}

/* Webkit browsers (Chrome, Safari, Edge) */
.table-container::-webkit-scrollbar {
    -webkit-appearance: none;
    width: auto;
    height: 40px !important;
    display: block;
}

/* Track */
.table-container::-webkit-scrollbar-track {
    background: #D9D9D9;
    margin: 0;
}

/* Thumb */
.table-container::-webkit-scrollbar-thumb {
    background: #575757;
    border-radius: 20px;
    min-height: 42px;
    border: none;
    background-image: 
        linear-gradient(to right,
            #575757 calc(50% - 4px),
            #FFFFFF calc(50% - 4px),
            #FFFFFF calc(50% - 2px),
            #575757 calc(50% - 2px),
            #575757 calc(50% + 2px),
            #FFFFFF calc(50% + 2px),
            #FFFFFF calc(50% + 4px),
            #575757 calc(50% + 4px)
        );
    background-repeat: no-repeat;
    background-size: 100% 16px;
    background-position: center;
}

/* Corner */
.table-container::-webkit-scrollbar-corner {
    background: #D9D9D9;
}

/* Mobile styles */
@media (max-width: 768px) {
    .table-container {
        overflow-x: auto;
    }
    
    /* Optional: Hide scrollbar on mobile while preserving functionality */
    .table-container::-webkit-scrollbar {
        display: none;  /* Hide scrollbar but keep scrolling functionality */
    }
}

/* Make clickable cells show pointer and add hover effect */
.results-table td.clickable-cell {
    cursor: pointer;
}

.clickable-cell:hover {
    background-color: #f0f0f0;
}

/* Preserve hover colors for cells with warning/error states */
.clickable-cell.error-cell:hover {
    background-color: #fbb4af;
}

.clickable-cell.warning-cell:hover {
    background-color: #ffd966;
}

.clickable-cell.invalid-cell:hover {
    background-color: #c7cace;
}

.results-table {
    width: auto;
    border-collapse: collapse;
    margin: 13px 0 0;
}

.results-table th,
.results-table td {
    padding: 10px 15px;
    border-bottom: 2px solid #949494;
    text-align: left;
    vertical-align: middle;
    min-width: 125px;
    width: 125px;
    max-width: 125px;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



.results-table th {
    font-size: 14px;
}

.results-table td {
    height: 50px;
    position: relative;
    padding: 10px 15px;
}

.results-table th:first-child,
.results-table td:first-child,
.results-table th:last-child,
.results-table td:last-child {
    padding: 10px;
    min-width: auto;
}

.results-table th:first-child,
.results-table td:first-child {
    position: sticky;
    left: 0;
    background-color: #F1F2F4; /* For header */
    z-index: 10;
    padding-left: 25px;
    padding-right: 25px;
    min-width: 166px;
    width: 166px;
    max-width: 166px;
}

.results-table td:first-child {
    background-color: #fff;
}


/* First column (left) shadow */
.results-table th:first-child::after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 100%;
    background: linear-gradient(to right, rgba(0,0,0,0.1), transparent), #F1F2F4;
    pointer-events: none;
}
.results-table td:first-child::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 100%;
    background: linear-gradient(to right, rgba(0,0,0,0.1), transparent), #ffffff;
    pointer-events: none;
}

/* Add these specific rules AFTER the above rules */
.results-table td.error-cell:first-child {
    background-color: #FDD3D0;
}

.results-table td.warning-cell:first-child {
    background-color: #FFE8A3;
}

.results-table td.invalid-cell:first-child {
    background-color: #E9ECEF;
}

.results-table th {
    background-color: #F1F2F4;
    font-weight: 600;
    color: #565D6B;
    height: 62px;  /* Add fixed height */
    padding-top: 0;  /* Remove padding since we're using fixed height */
    padding-bottom: 0;  /* Remove padding since we're using fixed height */
}

.small-save-icon {
    width: 10px;
    height: 10px;
    margin-right: 5px; /* Add some space between the icon and the text */
  }
  
.results-table td {
    color: #101828;
    font-family: 'Lato', sans-serif;
}

.results-table td input[type="checkbox"] {
    margin: 0;
}

.error-cell {
    background-color: #FDD3D0; /* Light Red for Errors */
}

.warning-cell {
    background-color: #FFE8A3; /* Light Yellow for Warnings */
}

.invalid-cell {
    background-color: #E9ECEF;
}

.error-message {
    color: #900B09; /* Red color for error messages */
}

.warning-message {
    color: #682D03; 
}

.invalid-message { 
    color: #565A5E;
}

.cell-message {
    font-size: 12px;
    padding-top: 6px;
    font-weight: 600;
    white-space: normal;
    margin-top: 5px; /* Ensure some space between full name and the message */
}



.results-table th:last-child,
.results-table td:last-child {
    position: sticky;
    right: 0;
    background-color: #F1F2F4;
    width: fit-content; /* or min-content */
    min-width: min-content; /* ensures it doesn't get too small */
    max-width: none; /* removes the max-width constraint */
}

.results-table td:last-child {
    background-color: #fff;
}

.owner-pill {
    display: inline-flex;
    align-items: center;
    background-color: #EAEEFB;
    border-radius: 16px;
    padding: 0 8px;
    height: 22px;
    width: auto;
    margin-left: 5px;
    flex-shrink: 0;
}

.owner-dot {
    width: 5px;
    height: 5px;
    margin-right: 6px;
}

.owner-text {
    color: #3067D4;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
}

.icons {
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
}

.icon {
    cursor: pointer;
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle;
}

.editable-row {
    background-color: #f9f9f9;
}

.editable-input {
    border: 1px solid #d1d5db;
    border-radius: 6px;
    padding: 8px 12px;
    font-size: 14px;
    color: #374151;
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    font-family: 'Lato', sans-serif;
}

.editable-input::placeholder {
    font-family: 'Lato', sans-serif;
}

.disabled-input {
    background-color: #f0f0f0;
    cursor: not-allowed;
    opacity: 0.7;
  }



.save-button-row-edit,
.cancel-button {
    background-color: #E3EBF7;
    color: #285192;
    border: none;
    border-radius: 6px;
    padding: 8px 12px;
    font-size: 12px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-right: 5px;
    transition: background-color 0.3s;
}

.cancel-button {
    background-color: #F7E3E3;
    color: #922828;
}

.save-button-row-edit:hover {
    background-color: #2553a1;
    color: #FFFFFF;
}

.cancel-button:hover {
    background-color: #c03a53;
    color: #FFFFFF;
}

.editable-buttons {
    padding: 0;
    border-bottom: 2px solid #949494;
    vertical-align: middle;
}

.buttons-container {
    display: flex;
    align-items: center;
    gap: 2px;
    height: 100%;
    box-sizing: border-box;
}

.fake-row-table {
    width: auto;
    border-collapse: collapse;
    margin: 0;
}

.fake-row-table td {
    text-align: left;
    white-space: nowrap;
    border-bottom: none;
    padding: 10px;
}

.import-data-button,
.new-employee-button {
    background: none;
    border: none;
    color: #190041;
    cursor: pointer;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    transition: color 0.3s;
}

.import-data-button:hover,
.new-employee-button:hover {
    color: #949494;
}

.import-data-button img,
.new-employee-button img {
    margin-right: 5px;
}

.footer-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 20px;
}

.total-members {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.pagination-controls {
    display: flex;
    gap: 5px;
}

.pagination-arrow,
.pagination-button {
    background-color: #f1f1f1;
    border: none;
    border-radius: 5px;
    color: #333;
    cursor: pointer;
    padding: 10px 15px;
    transition: background-color 0.3s, color 0.3s;
}

.pagination-arrow:hover,
.pagination-button:hover {
    background-color: #3067D4;
    color: #FFFFFF;
}

.pagination-button.active {
    background-color: #3067D4;
    color: #FFFFFF;
}

.pagination-ellipsis {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    color: #666;
    font-size: 16px;
}

.resolve-button,
.resolve-button-header {
    background-color: #DC4C64;
    color: #FFFFFF;
    padding: 7px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    box-shadow: 0 4px 9px -4px #DC4C64;
    transition: background-color 0.3s;
}

.resolve-button {
    padding: 10px 15px;
    justify-content: center;
}

.resolve-button:hover,
.resolve-button-header:hover {
    background-color: #c03a53;
}

.resolve-button-header {
    margin: 10px 0 5px;
}

.resolve-button img,
.resolve-button-header img {
    margin-left: 5px;
    width: 10px;
    height: 8.7px;
}

.status-cell {
    padding: 0;
    vertical-align: middle;
}

.status-pill {
    display: inline-flex;
    align-items: center;
    padding: 3px 6px;
    border-radius: 12px;
    background-color: #ECFDF3;
    color: #027A48;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Lato', sans-serif;
}

.status-pill.terminated {
    background-color: #CECCCE;
    color: #636065;
}

.status-dot {
    margin-right: 6px;
    width: 6px;
    height: 6px;
}

.results-section {
    position: relative;
    min-height: 300px;
}

.empty-state-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 11; 
}

.zero-hour-hover {
    cursor: pointer;
}


.hours-worked-container {
    display: inline-flex; 
    justify-content: flex-start;
    align-items: center;
  }
  
  
  .hours-info-icon {
    margin-left: 40px;
    cursor: pointer;
  }

.results-table td.zero-hour-hover {
    cursor: pointer;
}

.results-table td.zero-hour-hover:hover {
    background-color: #f0f0f0;
}

.results-table td.zero-hour-hover.error-cell:hover {
    background-color: #fbb4af;
}

.results-table td.zero-hour-hover.warning-cell:hover {
    background-color: #ffd966;
}

.results-table td.zero-hour-hover.invalid-cell:hover {
    background-color: #c7cace;
}

.results-table td:nth-child(2),
.results-table th:nth-child(2) {
  width: 200px;
  max-width: 200px;
}

.name-column {
    width: 2360px !important;
    max-width: 2360px !important;
    overflow: hidden;
}

.full-name-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.truncate-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    min-width: 0;
}

.truncate-name.with-owner-icon {
    max-width: calc(55%);
}

.truncate-name:not(.with-owner-icon) {
    max-width: 100%;
}

.noted-badge-icon {
    transform: scale(0.8);
    vertical-align: middle;
    margin-left: 4px;
    transition: filter 0.2s ease-in-out;
    cursor: pointer;
}

.noted-badge-icon:hover {
    filter: brightness(0.8);
}

.data-source-floating-display {
    position: fixed;
    background: #6D6D6D;
    color: white;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    max-width: 200px;
    word-wrap: break-word;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    pointer-events: none;
    z-index: 10000;
}

/* Styling for the sticky column */
.results-table .sticky-column {
    position: sticky;
    right: 0;
    background-color: #F1F2F4;
    z-index: 10;
    overflow: visible; /* Allow shadow overlay */
}

/* Shadow overlay within the sticky column */
.sticky-column .shadow-overlay {
    position: absolute;
    top: 0;
    left: -10px; /* Align shadow to the left of the sticky column */
    width: 10px; /* Width of the shadow */
    height: 100%; /* Full height of the table, ensuring consistency */
    background: linear-gradient(to left, rgba(0, 0, 0, 0.1), transparent); /* Soft gradient for shadow */
    pointer-events: none; /* Ensures it doesn’t interfere with interactions */
    z-index: -1; /* Places it behind the content */
}


/* Content inside the sticky column */
.sticky-column .sticky-column-content {
    position: relative;
    z-index: 1; /* Place above the shadow overlay */
}

.error-input {
    border: 1px solid #FF4D4F !important;
    border-radius: 4px;
  }
  
  .error-message-input {
    position: absolute;
    bottom: 5px;
    left: 0;
    color: #FF4D4F;
    font-size: 12px;
    word-wrap: break-word;
    white-space: normal;
    width: 100%;
    line-height: 1.2;  /* Added to ensure proper line spacing */
}

.input-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.results-table tr.editable-row {
    height: 140px;  /* Slightly increased to accommodate two-line messages */
}
.readonly-message {
    position: absolute;
    bottom: 5px;
    left: 0;
    color: #666666;
    font-size: 12px;
    margin-top: 4px;
    word-wrap: break-word;
    white-space: normal;
    width: 100%;
    line-height: 1.2;  /* Added to match error-message-input */
}
  .disabled-input {
    background-color: #f5f5f5 !important;
    cursor: not-allowed !important;
  }

/* Add this to your CSS */
.results-table td.has-noted-badge,
.results-table th:nth-child(8) {
  width: 160px;
  min-width: 160px;
  max-width: 160px;
}