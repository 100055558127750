.empty-state {
    text-align: center;
    padding: 20px 20px;
  }
  
  .empty-icon {
    width: 38.5px;
    height: 26.85px;
    margin-bottom: 10px;
  }
  
  .empty-state p {
    font-size: 20px;
    color: #757575;
  }
  
  .empty-buttons {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 40px;
  }
  
  .button-add-manually-empty, .button-upload-file-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    min-width: 150px;
    transition: background-color 0.3s;
    font-weight: bold;
    font-size: 12px;
  }
  
  .button-add-manually-empty span, .button-upload-file-empty span {
    margin-top: 2px; /* Adjust this value as needed */
  }
  
  
  .button-add-manually-empty {
    border: 1px solid #3067D4;
    color: #007bff;
    background-color: #fff;
  }
  
  .button-add-manually-empty:hover {
    background-color: #e6e6e6;
  }
  
  .button-upload-file-empty {
    border: none;
    color: #fff;
    background-color: #3067D4;
  }
  
  .button-upload-file-empty:hover {
    background-color: #2553a1;
  }
  
  .button-add-manually-empty img, .button-upload-file-empty img {
    margin-right: 5px;
    width: 17px;
    height: 17px;
  }
  