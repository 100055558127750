/* HelpModal.css */
.help-modal {
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 520px; /* Increased width */
    z-index: 1000;
  }
  
  .help-modal-content {
    position: relative;
    padding: 28px;
    padding-top: 40px; /* Increased top padding for more space above text */
  }
  
  .help-modal-close {
    position: absolute;
    top: 12px; /* Reduced top spacing to move X closer to corner */
    right: 12px; /* Reduced right spacing to move X closer to corner */
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
    padding: 4px;
    line-height: 1;
  }

  .help-modal-body {
    padding: 8px 0 24px;
    margin-top: 8px; /* Added margin to increase space below X */
  }
  
  .help-modal-title {
    font-size: 14px; /* Reduced text size */
    color: #333;
    margin: 0;
    text-align: center;
    line-height: 1.5;
    font-weight: normal;
  }
  
  .help-modal-divider {
    height: 1px;
    background-color: #E5E5E5;
    margin: 0 -28px; /* Match new content padding */
  }
  
  .help-modal-footer {
    display: flex;
    gap: 16px;
    padding-top: 24px;
  }
  
  .help-modal-button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    gap: 8px;
    min-height: 44px;
  }
  
  .help-modal-button img {
    width: 16px;
    height: 16px;
  }
  
  .restart-button {
    background-color: #f0f3f8;
    color: #285192; /* Updated color */
  }
  
  .external-button {
    background-color: #3B71CA; /* Updated color */
    color: white;
  }