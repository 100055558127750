.alert-tag {
  border-radius: 8px;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  position: relative;
}

.alert-tag.Warnings {
  background-color: #FFE8A3;
  color: #682D03;
}

.alert-tag.Errors {
  background-color: #FDD3D0;
  color: #900B09;
}

.alert-tag.Required-Fields {
  background-color: #E9ECEF;
  color: #343A40;
}

.alert-tag.Warnings.active {
  background-color: #E5A000;
  color: #401B01;
}

.alert-tag.Errors.active {
  background-color: #C00F0C;
  color: #FEE9E7;
}

.alert-tag.Required-Fields.active {
  background-color: #6C757D;
  color: #FFFFFF;
}

/* Inactive hover states */
.alert-tag.Warnings:not(.active):hover {
  background-color: #F7C56A;
  color: #5A2502;
}

.alert-tag.Errors:not(.active):hover {
  background-color: rgb(233, 175, 175);
  color: #500000;
}

.alert-tag.Required-Fields:not(.active):hover {
  background-color: #c1c4c7;
  color: #343A40;
}

.alert-tag.Warnings.active:hover {
  background-color: #E5A000;
  opacity: 0.8;
  color: #401B01;
}

.alert-tag.Errors.active:hover {
  background-color: #C00F0C;
  opacity: 0.8;
  color: #FEE9E7;
}

.alert-tag.Required-Fields.active:hover {
  background-color: #6C757D;
  opacity: 0.8;
  color: #FFFFFF;
}

.alert-icon {
  margin-right: 10px;
  vertical-align: middle;
}

.warnings-icon.inactive {
  width: 16px;
  height: 14px;
}

.warnings-icon.active {
  width: 16px;
  height: 14px;
}

.errors-icon {
  width: 14px;
  height: 14px;
}

.invalids-icon {
  width: 14px;
  height: 14px;
}

.alert-label {
  margin-right: 5px;
}

.alert-count {
  font-weight: 500;
}

.close-icon {
  margin-left: 12px;
  cursor: pointer;
  width: 7.5px;
  height: 8.5px;
  vertical-align: middle;
}