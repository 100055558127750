.hwm-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .hwm-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    position: relative;
    font-family: 'Lato', sans-serif;
  }
  
  .hwm-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px; /* Increased space between the header and the border */
    border-bottom: none;
  }
  
  .hwm-modal-header h2 {
    margin: 0;
    font-size: 20px;
    color: #4F4F4F;
  }
  
  .hwm-modal-close-icon {
    width: 14px; /* Reduced size by 33% to 14px */
    height: 14px;
    cursor: pointer;
  }
  
  .hwm-horizontal-rule {
    border: 0;
    border-top: 2px solid #e4e4e4;
  }
  
  .hwm-banner {
    display: flex;
    align-items: center;
    background-color: #F5F5F5;
    padding: 10px 0; /* Added padding on top and bottom */
    margin: 20px 0; /* Increased margin to add space above and below */
    border-left: 4px solid #FA8734; /* Left border */
    border-radius: 6px;
  }
  
  .hwm-banner-icon {
    margin: 0 10px;
  }
  
  .hwm-modal-section {
    display: flex;
    align-items: flex-start;
    margin-top:10px;
    padding-top: 10px;
  }
  
  .hwm-label {
    flex: 1;
    font-size: 20px;
    color: #181919;
    margin-bottom: 5px;
  }
  
  .hwm-input-container {
    flex: 2;
    position: relative;
    margin-right: 20px; /* Added margin to prevent bleeding off the right side */
  }

  .hwm-textarea-error {
    border: 1px solid red;
  }
  
  .hwm-textarea-error:focus {
    border: 1px solid red; /* Ensures the border stays red on focus */
    outline: none; /* Optionally remove the default focus outline */
  }
  
  
  .hwm-input,
  .hwm-textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Lato', sans-serif;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  }
  
  .hwm-warning-icon {
    position: absolute;
    right: 20px;
    top: 20%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
  }
  
  .hwm-description {
    font-size: 14px;
    color: #757575;
    margin-top: 5px;
    line-height: 160%; /* Set line height */
  }
  
  .hwm-textarea {
    resize: none; /* Prevent resizing */
  }
  
  .hwm-apply-button {
    display: block; /* Ensure the button is treated as a block element */
    margin: 20px auto 0; /* Center the button by setting left and right margins to auto */
    width: 244px;
    height: 36px;
    padding: 10px;
    background-color: #3067D4;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0 4px 9px -4px #3B71CA;
  }
  
  .hwm-apply-button:hover {
    background-color: #2553a1;
  }
  
  .tooltip-container {
    position: relative;
    cursor: default;
    text-decoration: underline;
    color: #3067D4;
  }
  
  .tooltip-content {
    display: none;
    position: absolute;
    top: 100%; /* Position below the text */
    left: 50%;
    transform: translateX(-50%);
    background: #4F4F4F;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    white-space: normal; /* Allows for normal text wrapping */
    z-index: 1000;
    width: 300px; /* Set a fixed width for the tooltip */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 5px; /* Small margin to separate tooltip from text */
  }
  
  .tooltip-container:hover .tooltip-content {
    display: block;
  }
  
  .hwm-textarea-error {
    border: 1px solid red;
  }
  
  .hwm-description-error {
    color: red;
  }
  
  .hwm-textarea-error::placeholder {
    color: red;
  }
  
  /* For Chrome, Safari, Edge, Opera */
.hwm-input[type='number']::-webkit-outer-spin-button,
.hwm-input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.hwm-input[type='number'] {
  -moz-appearance: textfield;
}
