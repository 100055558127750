/* OnboardingModal.css */

/* Overlay that covers the entire viewport */
.onboarding-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
}

/* Main modal container with responsive sizing */
.onboarding-modal-content {
  width: min(90vw, 1000px);
  height: min(90vh, 700px);
  aspect-ratio: 10/7;
  background: white;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: clamp(4px, 0.5vw, 8px) clamp(8px, 2vw, 16px) clamp(8px, 2vw, 16px);
  gap: 0;
  overflow: hidden;
}

/* Header section with step indicator and close button */
.onboarding-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

/* Step indicator text */
.onboarding-modal-step-indicator {
  font-size: clamp(16px, 2vw, 20px);
  font-weight: 500;
  color: #4F4F4F;
}

/* Close button styling */
.onboarding-modal-close {
  background: none;
  border: none;
  font-size: clamp(24px, 3vw, 34px);
  cursor: pointer;
  color: #808080;
  padding: 0;
}

/* Title section styling */
.onboarding-modal-title {
  text-align: center;
  font-weight: normal;
  font-size: clamp(16px, 2vw, 20px);
  width: 70%;
  margin: 0 auto 4px;
  color: #656565;
}

/* Image container styling */
.onboarding-modal-image {
  flex: 1;
  margin: 8px 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 0;
  overflow: hidden;
}

/* Image sizing and styling */
.onboarding-modal-image img {
  width: auto;
  height: 90%;
  object-fit: contain;
  object-position: center;
  box-shadow: 0px 4px 25px 8px rgba(0, 0, 0, 0.08);
}

/* Footer section containing navigation */
.onboarding-modal-footer {
  padding: 16px 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Navigation spacing container */
.onboarding-modal-nav-space {
  display: flex;
  align-items: center;
}

/* Progress dots container */
.onboarding-modal-dots {
  display: flex;
  gap: 14px;
}

/* Navigation wrapper for positioning */
.onboarding-modal-nav-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: min-content;
}

/* Base navigation button styles */
.onboarding-modal-nav-button {
  height: clamp(35px, 5vh, 45px);
  padding: clamp(4px, 1vw, 6px) clamp(12px, 2vw, 20px);
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
  font-size: clamp(12px, 1.5vw, 14px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato', sans-serif;
}

/* Back button positioning and styling */
.onboarding-modal-nav-button.onboarding-modal-back {
  position: absolute;
  right: calc(100% + clamp(20px, 4vw, 40px));
  background: none;
  border: none;
  color: #3B71CA;
  padding: 0;
  width: auto;
}


/* Continue and Finish button styling */
.onboarding-modal-nav-button.onboarding-modal-continue,
.onboarding-modal-nav-button.onboarding-modal-finish {
  position: absolute;
  left: calc(100% + clamp(20px, 4vw, 40px));
  background: #3B71CA;
  border: none;
  color: white;
}

/* Continue button specific spacing */
.onboarding-modal-nav-button.onboarding-modal-continue {
  padding-right: 16px;
}

/* Continue arrow icon styling */
.continue-arrow {
  width: clamp(12px, 1.5vw, 16px);
  height: auto;
  margin-left: 4px;
}

/* Progress dot styling */
.onboarding-modal-dot {
  width: clamp(12px, 1.5vw, 16px);
  height: clamp(12px, 1.5vw, 16px);
  border-radius: 50%;
  background: #ddd;
  transition: background-color 0.3s ease;
}

/* Active dot state */
.onboarding-modal-dot.active {
  background: #3B71CA;
}

/* Disabled back button state */
.onboarding-modal-nav-button.onboarding-modal-back.disabled {
  background: none;
  border: none;
  color: #ccc;
  cursor: not-allowed;
  opacity: 0.5;
}

/* Date range display styling */
.onboarding-modal-title .date-range {
  display: block;
  margin-top: 2px;
}

/* New feature callout styling */
.onboarding-modal-title .new-feature {
  display: block;
  margin-top: 8px;
}

/* New label styling */
.onboarding-modal-title .new-label {
  color: #3B71CA;
  font-weight: bold;
}

/* Tablet and small desktop adjustments */
@media screen and (max-width: 768px) {
  /* Keep only necessary responsive adjustments */
  .onboarding-modal-content {
    width: 95vw;
    height: 95vh;
  }

  /* Adjust button text size if needed */
  .onboarding-modal-nav-button {
    font-size: clamp(11px, 1.2vw, 14px);
  }
}

/* Mobile device adjustments */
@media screen and (max-width: 480px) {
  .onboarding-modal-content {
    width: 95vw;
    height: 95vh;
    aspect-ratio: auto;
  }
}