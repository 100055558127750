.main-content {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  background-color: #FFFFFF;
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.content-wrapper {
  width: 100%;
  max-width: 1443px;
  margin: 0 auto;
  text-align: left;
  padding: 0 20px;
  box-sizing: border-box;
}

/* Large desktop screens */
@media screen and (max-width: 1440px) {
  .content-wrapper {
    max-width: 1200px;
    width: calc(100% - 80px);
    margin: 0 auto;
  }
}

/* Medium desktop screens */
@media screen and (max-width: 1200px) {
  .content-wrapper {
    max-width: 1000px;
    width: calc(100% - 60px);
    margin: 0 auto;
  }
}

/* Small desktop and tablet landscape */
@media screen and (max-width: 1024px) {
  .content-wrapper {
    max-width: 900px;
    width: calc(100% - 40px);
    margin: 0 auto;
  }
}

/* Tablet portrait */
@media screen and (max-width: 768px) {
  .content-wrapper {
    max-width: 700px;
    width: calc(100% - 30px);
    padding: 0 15px;
    margin: 0 auto;
  }
}

/* Large mobile */
@media screen and (max-width: 576px) {
  .content-wrapper {
    max-width: 540px;
    width: calc(100% - 20px);
    padding: 0 10px;
    margin: 0 auto;
  }
}

/* Small mobile */
@media screen and (max-width: 375px) {
  .content-wrapper {
    max-width: 350px;
    width: calc(100% - 16px);
    padding: 0 8px;
    margin: 0 auto;
  }
}

/* Extra small mobile */
@media screen and (max-width: 320px) {
  .content-wrapper {
    max-width: 300px;
    width: calc(100% - 12px);
    padding: 0 6px;
    margin: 0 auto;
  }
}

/* Ultra small mobile/watch screens */
@media screen and (max-width: 280px) {
  .content-wrapper {
    max-width: 260px;
    width: calc(100% - 8px);
    padding: 0 4px;
    margin: 0 auto;
  }
}

/* Rest of your existing CSS */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 128, 128, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #007BFF;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}