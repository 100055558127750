.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-header-divider {
  border: 0;
  height: 1px;
  background-color: #e0e0e0;
  margin: 16px 0; /* Increased space above and below the horizontal line */
}

.rehire-banner {
  display: flex;
  align-items: center;
  background-color: #F5F5F5; /* Updated background color */
  padding: 15px 20px; /* Increased padding for height */
  margin: 10px 0; /* Adjusted margin to keep space consistent */
  margin-left: 20px;
  width: 80%; /* Set the width to 80% of the container */
  border-left: 4px solid #FA8734; /* Orange left border to match the info icon */
  color: #000000;
  font-size: 14px;
  border-radius: 6px;
}

.info-icon {
  width: 16px; /* Icon size */
  height: 16px; /* Icon size */
  margin-right: 8px; /* Space between the icon and the text */
  margin-left: 0px; /* Space between the left edge and the icon */
}

.discrepancy-text {
  margin: 0; /* Remove any extra margins */
  padding: 0px 20px; /* Adjust padding to match modal header */
  padding-bottom: 8px;
  text-align: left; /* Left-align the text */
  font-size: 16px; /* Ensure readable font size */
  color: #4F4F4F; /* Match color for consistency */
}

.modal-content {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  position: relative;
}

.bulk-conflict-modal .modal-table th:first-child,
.bulk-conflict-modal .modal-table td:first-child {
  width: 70%; /* Increase width of the first column (SOURCE) */
}

.bulk-conflict-modal .modal-table th:last-child,
.bulk-conflict-modal .modal-table td:last-child {
  width: 30%; /* Decrease width of the second column (PREVIEW) */
}

.modal-header,
.modal-subheader,
.modal-apply-button-wrapper {
  padding: 20px; /* Consistent padding across sections */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0;
}

.modal-header h2 {
  margin: 0; /* Keep existing margin reset */
  margin-left: 0; /* Ensure no left margin */
  font-size: 18px; /* Keep existing font size */
  font-weight: bold; /* Keep existing bold font */
  color: #4F4F4F; /* Keep existing color */
}


.modal-close-icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.modal-table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse; /* Ensures borders do not add extra space */
  table-layout: fixed; /* Ensures consistent column widths */
}

.modal-table th,
.modal-table td {
  padding: 15px 20px;
  border-bottom: 1px solid #949494; /* Consistent borders */
  text-align: left; /* Ensures text is aligned consistently */
  box-sizing: border-box; /* Consistent box-sizing */
}

.modal-table th:first-child,
.modal-table td:first-child {
  width: 40%; /* Set the first column to 40% */
}

.modal-table th:last-child,
.modal-table td:last-child {
  width: 60%; /* Set the second column to 60% */
}

.modal-table th {
  background-color: #F1F2F4;
  font-weight: bold;
  color: #565D6B;
  font-size: 14px;
}

.modal-table td {
  background-color: #fff;
  color: #333;
  vertical-align: middle;
  white-space: nowrap; /* Prevent the text from wrapping to a new line */
  overflow: hidden; /* Ensure content does not overflow the container */
  text-overflow: ellipsis; /* Display ellipsis ("...") when content is too long */
}


.modal-table td input[type="radio"] {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 10px;
  appearance: none;
  border: 1px solid #959595;
  outline: none;
  background-color: white;
  cursor: pointer;
  vertical-align: middle; /* Keep this to ensure baseline alignment */
  position: relative;
  top: -2px; /* Move the radio button up by 2px */
}


.modal-table td input[type="radio"]:checked {
  background-color: #3067D4;
  border-color: #3067D4;
  position: relative;
}

.modal-table td input[type="radio"]:checked::after {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-table td span {
  vertical-align: middle; /* Align the text vertically with the radio button */
}



.modal-button-container {
  padding-bottom: 4px; /* Reduce bottom padding */
  margin-bottom: 0; /* Remove any extra bottom margin */
  text-align: center; /* Center the button horizontally */
}


.modal-apply-button {
  width: 244px;
  height: 36px;
  display: block;
  margin: 20px auto; /* Center the button horizontally */
  background-color: #3067D4; /* Existing styles */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
  box-shadow: 0 4px 9px -4px #3B71CA;
}


.modal-apply-button:hover {
  background-color: #2553a1;
}

.modal-header.previous-census {
  margin-top: 20px;
  margin-bottom: 10px;
}

.modal-header.previous-census h2 {
  margin-bottom: 5px;
}

/* General styling for the date input */
input[type="date"] {
  appearance: none; /* Remove default styling */
  -webkit-appearance: none; /* Remove default styling for WebKit browsers */
  -moz-appearance: none; /* Remove default styling for Mozilla browsers */
  background-color: white; /* Set background color */
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 8px; /* Rounded corners */
  padding: 10px 15px; /* Padding inside the input */
  font-size: 16px; /* Font size */
  color: #333; /* Text color */
  width: 100%; /* Full width */
  box-sizing: border-box; /* Ensure padding does not affect width */
  outline: none; /* Remove default focus outline */
  cursor: pointer; /* Pointer cursor for interactivity */
}

input[type="date"] {
  appearance: none; /* Remove default styling */
  -webkit-appearance: none; /* Remove default styling for WebKit browsers */
  -moz-appearance: none; /* Remove default styling for Mozilla browsers */
  background-color: white; /* Set background color */
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 8px; /* Rounded corners */
  padding: 8px 12px; /* Smaller padding inside the input */
  font-size: 14px; /* Slightly smaller font size */
  color: #333; /* Text color */
  width: auto; /* Auto width to fit content */
  min-width: 120px; /* Minimum width to ensure it's not too small */
  box-sizing: border-box; /* Ensure padding does not affect width */
  outline: none; /* Remove default focus outline */
  cursor: pointer; /* Pointer cursor for interactivity */
  vertical-align: middle; /* Align with the checkbox */
}

input[type="date"]::placeholder {
  color: #999; /* Light gray color for placeholder */
  font-style: italic; /* Italic style for placeholder text */
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0; /* Hide the default calendar icon */
  cursor: pointer; /* Still allow cursor change to pointer */
}

input[type="date"]:hover {
  border-color: #999; /* Change border color on hover */
}

input[type="date"]:focus {
  border-color: #3067D4; /* Blue border on focus */
  box-shadow: 0 0 5px rgba(48, 103, 212, 0.5); /* Soft blue shadow */
}
.conflict-modal-date-input-error {
  border: 1px solid #dc3545 !important;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.conflict-modal-error-message {
  color: #dc3545;
  font-size: 14px;
  text-align: center;
  padding-bottom: 16px;
}