.introduction-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px; /* Added more left padding */
    padding-left: 10%;
    background-color: #FFFFFF;
    overflow: hidden; /* Prevent horizontal scroll */
  }
  
  .introduction-text {
    width: 45%; /* Reduced width to give more space for the image */
    padding-right: 20px;
  }
  
  .breadcrumb {
    font-size: 16px;
    color: #757575;
    margin-bottom: 10px;
  }
  
  .introduction-main-heading {
    font-size: 28px;
    font-weight: bold;
    color: #1B2F4A;
    margin-bottom: 0px;
  }
  
  .introduction-main-subheading {
    font-size: 40px;
    font-weight: bold;
    color: #4f4f4f;
    margin-bottom: 20px;
  }
  
  .introduction-subheading {
    font-size: 24px;
    font-weight: bold;
    color: #1B2F4A;
    margin-bottom: 20px;
  }
  
  .introduction-paragraph {
    font-size: 16px;
    color: #181919;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .introduction-link {
    color: #007BFF;
    text-decoration: underline;
  }
  
  .introduction-list {
    font-size: 16px;
    color: #181919;
    line-height: 1.5;
    margin-bottom: 20px;
    padding-left: 20px;
  }
  
  .introduction-button {
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #3067D4;
    border: none;
    border-radius: 6px; /* Increase border-radius by 20% */
    padding: 15px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .introduction-button:hover {
    background-color: #0056b3;
  }
  
  .arrow-icon {
    margin-left: 10px; /* Add some space between the text and the icon */
    width: 16px; /* Adjust the size as needed */
    height: 19px;
  }
  
  .introduction-image-container {
    width: 55%; /* Increased width to allow image to bleed */
    overflow: hidden; /* Ensure no horizontal scroll */
    position: absolute; /* Positioning the image to bleed off the right */
    right: 0;
    top: 0;
    bottom: 0;
  }
  
  .introduction-image {
    width: 100%; /* Allow image to bleed off the right */
    height: auto;
    object-fit: cover;
    position: relative;
    right: -20%; /* Move the image to the right */
    top: 5%;
  }
  