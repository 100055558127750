.alerts-wrapper {
  display: flex;
  align-items: center;
  margin-top: 22px;
}

.review-label {
  margin-right: 22px; /* Set 22px space after the "Review for:" label */
  font-weight: 500;
  color: #75668D; /* Adjust this color as needed */
}

.alerts-container {
  display: flex;
  gap: 22px; /* Set 22px spacing between alert tags */
}
