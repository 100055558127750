.upload-file-container {
  width: 100%;
}

.upload-options {
  margin-bottom: 30px;
}

.option {
  margin-bottom: 30px;
}

.option-title {
  font-size: 24px;
  color: #4F4F4F;
  font-weight: 500;
  margin-bottom: 20px;  /* Increased from 15px to 20px for more space */
}

.download-template, .learn-about-reports {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #3067D4;
  font-size: 14px;
  font-weight: 600;
  margin-left: 20px;
}

.download-template img, .learn-about-reports img {
  margin-right: 8px;
}

.learn-about-reports .lightbulb-icon {
  width: 20px;
  height: 20px;
}

.file-upload-area {
  width: 100%;
  height: auto;  /* Changed from fixed height to auto */
  border: 2px dashed #CCCCCC;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  position: relative;
  cursor: pointer;
  padding: 30px 20px;  /* Increased padding top and bottom from 20px to 30px */
  box-sizing: border-box;
}

.upload-icon {
  width: 28px;
  height: 32px;
  margin-bottom: 15px;  /* Increased from 10px to 15px for more space */
}

.upload-text {
  font-size: 18px;
  color: #000000;
  margin-bottom: 15px;
  font-weight: bold;
}

.browse-link {
  color: #3067D4;
  cursor: pointer;
  text-decoration: underline;
}

.file-types {
  font-size: 14px;
  color: #4F4F4F;
  text-align: center;
  line-height: 1.8;
  margin-top: 5px;  /* Added to increase space above this text */
}

.file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.drag-over {
  border-color: #3067D4;
  background-color: #F0F8FF;
}