/* Overlay */
.validation-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Content */
.validation-modal-content {
    background: white;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;  /* Increased width */
    text-align: center;
    position: relative;
}

/* Top Red Banner */
.validation-modal-header {
    background-color: #DC4C64;
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.validation-modal-header h2 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.validation-modal-header .close-icon {
    font-size: 24px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

/* Message */
.validation-modal-message {
    color: #4F4F4F;
    margin: 20px;
    text-align: left;
}

.validation-modal-message ul {
    list-style-type: none;
    padding: 0;
}

.validation-modal-message li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.validation-modal-message .warning-icon {
    margin-right: 10px;
    width: 20px;
}

/* Divider */
.validation-modal-divider {
    height: 1px;
    background-color: #F5F5F5;
    margin: 0 20px;
}

/* Actions */
.validation-modal-actions {
    display: flex;
    justify-content: center;
    padding: 20px;
}

/* Buttons */
.validation-modal-close-button {
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    background-color: white;
    color: #DC4C64;
    border: 2px solid #DC4C64;
}

.validation-modal-close-button:hover {
    background-color: #f0f0f0;
}
